<template>
	<v-card
		v-if="!signedUpStatus.signedUp"
		:loading="loading && 'deep-purple'"
		class="mx-auto my-12 rounded-lg login-card"
		max-width="600"
		outlined
	>
		<!-- <template slot="progress">
			<v-progress-liner color="deep-purple" height="10" indeterminate> </v-progress-liner>
		</template> -->
		<v-card-title>Sign Up</v-card-title>

		<v-banner single-line transition="slide-y-transition" v-if="errorMessage" color="pink lighten-4">
			<v-chip close color="pink" dark @click:close="clearErrorMessage" v-if="errorMessage.length > 0">
				{{ errorMessage }}
			</v-chip>
		</v-banner>

		<template>
			<validation-observer ref="observer" v-slot="{ invalid }">
				<form @submit.prevent="handleSignup">
					<validation-provider v-slot="{ errors }" name="email" rules="required|email">
						<v-text-field v-model="signingUser.email" :error-messages="errors" label="E-mail" required></v-text-field>
					</validation-provider>

					<validation-provider v-slot="{ errors }" name="Username" rules="required|max:12">
						<v-text-field
							v-model="signingUser.username"
							:counter="12"
							:error-messages="errors"
							label="User Name"
							required
						></v-text-field>
					</validation-provider>

					<!-- <validation-provider v-slot="{ errors }" name="password" rules="required" ref="password">
            <v-text-field
              v-model="signingUser.password"
              :error-messages="errors"
              label="Password"
              required
              type="password"
            >
            </v-text-field>
          </validation-provider>

		  <validation-provider v-slot="{errors}" name="password_confirmation" rules="">

		  </validation-provider> -->

					<validation-provider rules="required" v-slot="{ errors }" name="password">
						<v-text-field
							v-model="signingUser.password"
							type="password"
							:error-messages="errors"
							required
							label="Password"
						/>
					</validation-provider>

					<validation-provider
						vid="confirm"
						rules="required|confirmed:password"
						v-slot="{ errors }"
						name="password_confirmation"
					>
						<v-text-field
							v-model="signingUser.confirmPassword"
							type="password"
							:error-messages="errors"
							required
							label="Confirm Password"
						/>
					</validation-provider>

					<div class="form-buttons">
						<v-btn class="mr-5 deep-purple white--text" type="submit" :disabled="invalid" :loading="signedLoading">
							Sign up
						</v-btn>
						<v-btn @click="clear"> clear </v-btn>
					</div>
				</form>
			</validation-observer>
		</template>
	</v-card>
	<v-alert
		class="signup-alert"
		v-else
		rounded
		color="pink"
		dark
		prominent
		type="success"
		transition="scale-transition"
		elevation="24"
		colored-border
	>
		<v-row align="center">
			<v-col class="grow"> Successfully Signed up! </v-col>
			<v-col class="shrink">
				<v-btn class="deep-purple white--text" @click="loginPage">Go to Login</v-btn>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
import { required, confirmed, email, max, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import SigningUser from "../models/signingUser";
import { mapGetters } from "vuex";

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			signingUser: new SigningUser("", "", "", ""),
			loading: false,
			errorMessage: "",
		};
	},
	computed: {
		...mapGetters({
			signedUpStatus: "signup/getSignupStatus",
			signedLoading: "signup/getLoadingSigning",
		}),
	},

	methods: {
		async handleSignup() {
			this.loading = true;
			const isValid = await this.$refs.observer.validate();
			if (!isValid) {
				this.loading = false;
				return;
			}
			if (
				this.signingUser.email &&
				this.signingUser.username &&
				this.signingUser.password &&
				this.signingUser.confirmPassword
			) {
				this.$store.dispatch("signup/signup", this.signingUser).then(
					() => {
						this.$store.commit("auth/fetchLoggingUserEmail", this.signingUser.email, { root: true });
						this.$store.commit("auth/fetchingLoggingUserPassword", this.signingUser.password, {
							root: true,
						});
						// this.$router.push("/login");
					},
					(error) => {
						this.loading = false;
						this.errorMessage = error.toString() || error.response.data.error || error.response.data.general;
					}
				);
			}
		},
		clearErrorMessage() {
			this.errorMessage = null;
		},
		clear() {
			this.signingUser.username = "";
			this.signingUser.email = "";
			this.signingUser.password = "";
			this.signingUser.confirmPassword = "";
			this.$refs.observer.reset();
		},
		loginPage() {
			this.$router.push("/login");
		},
	},
};

setInteractionMode("eager");

extend("required", {
	...required,
	message: "{_field_} can not be empty",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});

extend("confirmed", {
	...confirmed,
	message: "Passwords do not match",
});
</script>

<style lang="scss">
.login-card {
	padding: 2%;
}
.form-buttons {
	padding-top: 1.5vh;
}
.signup-alert {
	margin-top: 10vh !important;
}
</style>
