var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.signedUpStatus.signedUp)?_c('v-card',{staticClass:"mx-auto my-12 rounded-lg login-card",attrs:{"loading":_vm.loading && 'deep-purple',"max-width":"600","outlined":""}},[_c('v-card-title',[_vm._v("Sign Up")]),(_vm.errorMessage)?_c('v-banner',{attrs:{"single-line":"","transition":"slide-y-transition","color":"pink lighten-4"}},[(_vm.errorMessage.length > 0)?_c('v-chip',{attrs:{"close":"","color":"pink","dark":""},on:{"click:close":_vm.clearErrorMessage}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1):_vm._e(),[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSignup.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.signingUser.email),callback:function ($$v) {_vm.$set(_vm.signingUser, "email", $$v)},expression:"signingUser.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Username","rules":"required|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":12,"error-messages":errors,"label":"User Name","required":""},model:{value:(_vm.signingUser.username),callback:function ($$v) {_vm.$set(_vm.signingUser, "username", $$v)},expression:"signingUser.username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"required":"","label":"Password"},model:{value:(_vm.signingUser.password),callback:function ($$v) {_vm.$set(_vm.signingUser, "password", $$v)},expression:"signingUser.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"confirm","rules":"required|confirmed:password","name":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"required":"","label":"Confirm Password"},model:{value:(_vm.signingUser.confirmPassword),callback:function ($$v) {_vm.$set(_vm.signingUser, "confirmPassword", $$v)},expression:"signingUser.confirmPassword"}})]}}],null,true)}),_c('div',{staticClass:"form-buttons"},[_c('v-btn',{staticClass:"mr-5 deep-purple white--text",attrs:{"type":"submit","disabled":invalid,"loading":_vm.signedLoading}},[_vm._v(" Sign up ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)],1)]}}],null,false,4239843509)})]],2):_c('v-alert',{staticClass:"signup-alert",attrs:{"rounded":"","color":"pink","dark":"","prominent":"","type":"success","transition":"scale-transition","elevation":"24","colored-border":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Successfully Signed up! ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{staticClass:"deep-purple white--text",on:{"click":_vm.loginPage}},[_vm._v("Go to Login")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }